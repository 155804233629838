<script lang="ts" setup>
const props = withDefaults(defineProps<{
  dark?: boolean,
  hideSiteName?: boolean,
}>(), {
  dark: false,
  hideSiteName: false
})
const url = useRequestURL()

const logoUrl = computed<string|undefined>(() => {
  if (url.hostname === 'crmig.org') {
    return '/img/crmig-92x92.png'
  }

  return '/img/rhinocalls-192x192.png'
})

const siteName = computed<string>(() => {
  if (url.hostname === 'crmig.org') {
    return 'CRM IG'
  }
  return 'RhinoCalls'
})
</script>

<template>
  <NuxtLink :to="{ name: 'index' }" class="block cursor-pointer">
    <img v-if="logoUrl" class="align-middle object-contain max-h-full mr-2" :src="logoUrl" :alt="siteName" />
    <span
      v-if="!hideSiteName"
      class="align-middle font-semibold text-3xl w-2/3"
      :class="{
        'text-white': dark,
        'text-black': !dark,
      }"
    >{{ siteName }}</span>
  </NuxtLink>
</template>
